import React, { useContext, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  CardHeader,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toastError } from '../../util/toast';
import img from '../../assets/img/logo_novo_saque.png';

import { AuthContext } from '../../context/AuthContext';
import { LoadingContext } from '../../context/loading/LoadingContext';

const Login = (props) => {
  const { register, handleSubmit, errors } = useForm();

  const { login, error, isAuthenticated, clearErrors, user } =
    useContext(AuthContext);

  const { showLoader, hideLoader } = useContext(LoadingContext);

  useEffect(() => {
    if (isAuthenticated) {
      hideLoader();
      console.log('ESTA AUNTETICADO');
      console.log(user);
      props.history.push(
        user &&
          (user.role_default == 'user_store_register' ||
            user.role_default == 'user_corban_register' ||
            user.role_default == 'user_subest_register')
          ? '/admin/contracts'
          : '/admin/contracts'
      );
    }

    if (error) {
      hideLoader();

      if (typeof error === 'object') {
        error.forEach((e) => {
          toastError(e.msg);
        });
      } else {
        toastError(error);
      }

      clearErrors();
    }
  }, [error, isAuthenticated, props.history]);

  function onSubmit(data) {
    showLoader();
    login(data);
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-novo-saque">
            <img src={img} className="img-fluid" alt="Logo Novo Saque" />
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Faça seu login</small>
            </div>
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    id="email"
                    name="email"
                    className="form-control"
                    ref={register({
                      required: 'O email é obrigatório',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Por favor digite um email válido',
                      },
                    })}
                  />
                </InputGroup>
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="Senha"
                    type="password"
                    autoComplete="new-password"
                    id="password"
                    name="password"
                    className="form-control"
                    ref={register({
                      required: 'A senha é obrigatório',
                      minLength: {
                        value: 6,
                        message: 'A senha deve ter pelo menos 6 caracteres',
                      },
                    })}
                  />
                </InputGroup>
                {errors.password && (
                  <small className="text-danger">
                    {errors.password.message}
                  </small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-3 btn-block"
                  color="primary"
                  type="submit"
                >
                  Entrar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="12" className="text-center">
            <Link className="text-light" to="/forgot">
              Esqueceu sua senha?
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
