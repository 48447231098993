import React, { useState, useEffect } from 'react';
import {
  Button,
  CustomInput,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from 'reactstrap';
import api from '../../services/api';
import { toastError } from '../../util/toast';
import {
  VisibilityUserMasterButton,
  VisibilityByUserRoles,
} from '../../util/visibility';

// reactstrap components

const Header = ({
  totalCustomers,
  totalCommissionValue,
  totalContractValue,
  totalPayments,
  operateSimulator,
  documentPending,
  sendLink,
  waitingDocumentosAnalitcs,
  waitingSignature,
  contractSigned,
  contractPaid,
  commissionPaid,
  canceled,
  chargebacked,
  riskAnalysis,
  pendingLife,
  paymentReturned,
  handleOnClickCard,
  handleOnClickChargebackCard,
  handleClickModalDate,
  filterDateType,
  pipeStatus,
  reanalysis,
  refused,
  disputeStatus,
  minFilterDate,
  maxFilterDate,
  minRegisterDate,
  maxRegisterDate,
  minPaymentDate,
  maxPaymentDate,
  minCommissionDate,
  maxCommissionDate,
  handlerOnClickFilter,
  handleClearFilterRegisterDate,
  handleClearFilterPaymentDate,
  handleClearFilterCommissionDate,
  setMinRegisterDate,
  setMaxRegisterDate,
  setMinPaymentDate,
  setMaxPaymentDate,
  setMinCommissionDate,
  setMaxCommissionDate,
  setFilterDateType,
  setMinFilterDate,
  setMaxFilterDate,
  totalValue,
  totalValueCanceled,
  totalValuePaid,
  requestFinish,
  hasFgts,
  contractPending,
  gradient = 'bg-gradient-info',
}) => {
  return (
    <>
      <div className={`header ${gradient} pb-8 pt-5 pt-md-7`}>
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            {/* <VisibilityByUserRoles roles={["master", "operational"]}>
              <Row>
                <Col lg="6" xl="4">
                  <a style={{ cursor: 'pointer' }} onClick={(e) => handleOnClickCard(7, null)}>
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h6"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total Vendidos
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalValue}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
                <Col lg="6" xl="4">
                  <a style={{ cursor: 'pointer' }} onClick={(e) => handleOnClickCard(7, null)}>
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h6"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total Pagos
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalValuePaid}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
                <Col lg="6" xl="4">
                  <a style={{ cursor: 'pointer' }} onClick={(e) => handleOnClickCard(7, null)}>
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h6"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total Cancelados
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalValueCanceled}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
              </Row>
            </VisibilityByUserRoles> */}
            <Row className="mt-4">
              <Col lg="3">
                <FormGroup>
                  <Label
                    className="form-control-label text-white"
                    htmlFor="status"
                  >
                    Filtro por data
                  </Label>
                  <CustomInput
                    type="select"
                    id="filter_date_type"
                    name="filter_date_type"
                    onChange={(e) => setFilterDateType(e.target.value)}
                    value={filterDateType}
                  >
                    <option value="created_at_custom">Data de Cadastro</option>
                    <option value="payment_date">
                      Data de Pagamento do Contrato
                    </option>
                    <option value="commission_payment_date">
                      Data de Pagamento da Comissão
                    </option>
                    {!hasFgts && (
                      <option value="credit_card_payment_date">
                        Data de Transação do Cartão
                      </option>
                    )}
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <Label
                    className="form-control-label text-white"
                    htmlFor="status"
                  >
                    Data inicial
                  </Label>
                  <CustomInput
                    type="date"
                    id="date_init_filter"
                    name="date_init_filter"
                    className="form-control form-control-alternative"
                    onChange={(e) => setMinFilterDate(e.target.value)}
                    value={minFilterDate}
                  ></CustomInput>
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <Label
                    className="form-control-label text-white"
                    htmlFor="status"
                  >
                    Data final
                  </Label>
                  <CustomInput
                    type="date"
                    id="date_end_filter"
                    name="date_end_filter"
                    className="form-control form-control-alternative"
                    onChange={(e) => setMaxFilterDate(e.target.value)}
                    value={maxFilterDate}
                  ></CustomInput>
                </FormGroup>
              </Col>
              <Col lg="3" className="d-flex align-items-center">
                <Button
                  color="primary"
                  style={{ height: 45 }}
                  onClick={() => handlerOnClickFilter(false, false)}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
            <br></br>
            {requestFinish && (
              <>
                <Row>
                  <Col lg="6" xl="3" className="my-2">
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleOnClickCard(7, null)}
                    >
                      <Card
                        className={`card-stats mb-4 mb-xl-0 ${
                          pipeStatus == 7 && 'isSelectedFilter'
                        }`}
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h6"
                                className={`text-uppercase mb-0 ${
                                  pipeStatus == 7
                                    ? 'isSelectedFilterText'
                                    : 'text-muted'
                                }`}
                              >
                                {hasFgts
                                  ? 'Aguardando formalização'
                                  : 'Link enviado'}
                              </CardTitle>
                              <span
                                className={`h2 font-weight-bold mb-0 ${
                                  pipeStatus == 7 && 'isSelectedFilterText'
                                }`}
                              >
                                {sendLink}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </a>
                  </Col>
                  <Col lg="6" xl="3" className="my-2">
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleOnClickCard(1, null)}
                    >
                      <Card
                        className={`card-stats mb-4 mb-xl-0 ${
                          pipeStatus == 1 && 'isSelectedFilter'
                        }`}
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h6"
                                className={`text-uppercase mb-0 ${
                                  pipeStatus == 1
                                    ? 'isSelectedFilterText'
                                    : 'text-muted'
                                }`}
                              >
                                {hasFgts
                                  ? 'Pendente Documentos'
                                  : 'Documentos Pendentes'}
                              </CardTitle>
                              <span
                                className={`h2 font-weight-bold mb-0 ${
                                  pipeStatus == 1 && 'isSelectedFilterText'
                                }`}
                              >
                                {documentPending}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </a>
                  </Col>
                  {hasFgts && (
                    <Col lg="6" xl="3" className="my-2">
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => handleOnClickCard(13, null)}
                      >
                        <Card
                          className={`card-stats mb-4 mb-xl-0 ${
                            pipeStatus == 13 && 'isSelectedFilter'
                          }`}
                        >
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h6"
                                  className={`text-uppercase mb-0 ${
                                    pipeStatus == 13
                                      ? 'isSelectedFilterText'
                                      : 'text-muted'
                                  }`}
                                >
                                  Reanálise
                                </CardTitle>
                                <span
                                  className={`h2 font-weight-bold mb-0 ${
                                    pipeStatus == 13 && 'isSelectedFilterText'
                                  }`}
                                >
                                  {reanalysis}
                                </span>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </a>
                    </Col>
                  )}
                  {hasFgts && (
                    <Col lg="6" xl="3" className="my-2">
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => handleOnClickCard(16, null)}
                      >
                        <Card
                          className={`card-stats mb-4 mb-xl-0 ${
                            pipeStatus == 16 && 'isSelectedFilter'
                          }`}
                        >
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h6"
                                  className={`text-uppercase mb-0 ${
                                    pipeStatus == 16
                                      ? 'isSelectedFilterText'
                                      : 'text-muted'
                                  }`}
                                >
                                  Biometria Facial em Análise
                                </CardTitle>
                                <span
                                  className={`h2 font-weight-bold mb-0 ${
                                    pipeStatus == 16 && 'isSelectedFilterText'
                                  }`}
                                >
                                  {pendingLife}
                                </span>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </a>
                    </Col>
                  )}
                  {hasFgts && (
                    <Col lg="6" xl="3" className="my-2">
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => handleOnClickCard(15, null)}
                      >
                        <Card
                          className={`card-stats mb-4 mb-xl-0 ${
                            pipeStatus == 15 && 'isSelectedFilter'
                          }`}
                        >
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h6"
                                  className={`text-uppercase mb-0 ${
                                    pipeStatus == 15
                                      ? 'isSelectedFilterText'
                                      : 'text-muted'
                                  }`}
                                >
                                  Análise de Risco
                                </CardTitle>
                                <span
                                  className={`h2 font-weight-bold mb-0 ${
                                    pipeStatus == 15 && 'isSelectedFilterText'
                                  }`}
                                >
                                  {riskAnalysis}
                                </span>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </a>
                    </Col>
                  )}
                  {!hasFgts && (
                    <Col lg="6" xl="3" className="my-2">
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => handleOnClickCard(2, null)}
                      >
                        <Card
                          className={`card-stats mb-4 mb-xl-0 ${
                            pipeStatus == 2 && 'isSelectedFilter'
                          }`}
                        >
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h6"
                                  className={`text-uppercase mb-0 ${
                                    pipeStatus == 2
                                      ? 'isSelectedFilterText'
                                      : 'text-muted'
                                  }`}
                                >
                                  Análise Documental
                                </CardTitle>
                                <span
                                  className={`h2 font-weight-bold mb-0 ${
                                    pipeStatus == 2 && 'isSelectedFilterText'
                                  }`}
                                >
                                  {waitingDocumentosAnalitcs}
                                </span>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </a>
                    </Col>
                  )}
                  {!hasFgts && (
                    <Col lg="6" xl="3" className="my-2">
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => handleOnClickCard(3, null)}
                      >
                        <Card
                          className={`card-stats mb-4 mb-xl-0 ${
                            pipeStatus == 3 && 'isSelectedFilter'
                          }`}
                        >
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h6"
                                  className={`text-uppercase mb-0 ${
                                    pipeStatus == 3
                                      ? 'isSelectedFilterText'
                                      : 'text-muted'
                                  }`}
                                >
                                  Aguardando Assinatura
                                </CardTitle>
                                <span
                                  className={`h2 font-weight-bold mb-0 ${
                                    pipeStatus == 3 && 'isSelectedFilterText'
                                  }`}
                                >
                                  {waitingSignature}
                                </span>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </a>
                    </Col>
                  )}
                  {hasFgts && (
                    <Col lg="6" xl="3" className="my-2">
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => handleOnClickCard(14, null)}
                      >
                        <Card
                          className={`card-stats mb-4 mb-xl-0 ${
                            pipeStatus == 14 && 'isSelectedFilter'
                          }`}
                        >
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h6"
                                  className={`text-uppercase mb-0 ${
                                    pipeStatus == 14
                                      ? 'isSelectedFilterText'
                                      : 'text-muted'
                                  }`}
                                >
                                  Pendente Análise
                                </CardTitle>
                                <span
                                  className={`h2 font-weight-bold mb-0 ${
                                    pipeStatus == 14 && 'isSelectedFilterText'
                                  }`}
                                >
                                  {contractPending}
                                </span>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </a>
                    </Col>
                  )}
                  <Col lg="6" xl="3" className="my-2">
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleOnClickCard(4, null)}
                    >
                      <Card
                        className={`card-stats mb-4 mb-xl-0 ${
                          pipeStatus == 4 && 'isSelectedFilter'
                        }`}
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h6"
                                className={`text-uppercase mb-0 ${
                                  pipeStatus == 4
                                    ? 'isSelectedFilterText'
                                    : 'text-muted'
                                }`}
                              >
                                Contratos Assinados - Aguardando Pagamento
                              </CardTitle>
                              <span
                                className={`h2 font-weight-bold mb-0 ${
                                  pipeStatus == 4 && 'isSelectedFilterText'
                                }`}
                              >
                                {contractSigned}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </a>
                  </Col>
                  <Col lg="6" xl="3" className="my-2">
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleOnClickCard(5, null)}
                    >
                      <Card
                        className={`card-stats mb-4 mb-xl-0 ${
                          pipeStatus == 5 && 'isSelectedFilter'
                        }`}
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h6"
                                className={`text-uppercase mb-0 ${
                                  pipeStatus == 5
                                    ? 'isSelectedFilterText'
                                    : 'text-muted'
                                }`}
                              >
                                Contratos Pagos
                              </CardTitle>
                              <span
                                className={`h2 font-weight-bold mb-0 ${
                                  pipeStatus == 5 && 'isSelectedFilterText'
                                }`}
                              >
                                {contractPaid}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </a>
                  </Col>
                  <Col lg="6" xl="3" className="my-2">
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleOnClickCard(8, null)}
                    >
                      <Card
                        className={`card-stats mb-4 mb-xl-0 ${
                          pipeStatus == 8 && 'isSelectedFilter'
                        }`}
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h6"
                                className={`text-uppercase mb-0 ${
                                  pipeStatus == 8
                                    ? 'isSelectedFilterText'
                                    : 'text-muted'
                                }`}
                              >
                                Comissões Pagas
                              </CardTitle>
                              <span
                                className={`h2 font-weight-bold mb-0 ${
                                  pipeStatus == 8 && 'isSelectedFilterText'
                                }`}
                              >
                                {commissionPaid}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </a>
                  </Col>
                  <Col lg="6" xl="3" className="my-2">
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleOnClickCard(6, null)}
                    >
                      <Card
                        className={`card-stats mb-4 mb-xl-0 ${
                          pipeStatus == 6 && 'isSelectedFilter'
                        }`}
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h6"
                                className={`text-uppercase mb-0 ${
                                  pipeStatus == 6
                                    ? 'isSelectedFilterText'
                                    : 'text-muted'
                                }`}
                              >
                                Cancelado
                              </CardTitle>
                              <span
                                className={`h2 font-weight-bold mb-0 ${
                                  pipeStatus == 6 && 'isSelectedFilterText'
                                }`}
                              >
                                {canceled}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </a>
                  </Col>
                  {!hasFgts && (
                    <Col lg="6" xl="3" className="my-2">
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => handleOnClickChargebackCard()}
                      >
                        <Card
                          className={`card-stats mb-4 mb-xl-0 ${
                            disputeStatus == 2 && 'isSelectedFilter'
                          }`}
                        >
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h6"
                                  className={`text-uppercase mb-0 ${
                                    disputeStatus == 2
                                      ? 'isSelectedFilterText'
                                      : 'text-muted'
                                  }`}
                                >
                                  Chargeback
                                </CardTitle>
                                <span
                                  className={`h2 font-weight-bold mb-0 ${
                                    disputeStatus == 2 && 'isSelectedFilterText'
                                  }`}
                                >
                                  {chargebacked}
                                </span>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </a>
                    </Col>
                  )}
                  <Col lg="6" xl="3" className="my-2">
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleOnClickCard(9, null)}
                    >
                      <Card
                        className={`card-stats mb-4 mb-xl-0 ${
                          pipeStatus == 9 && 'isSelectedFilter'
                        }`}
                      >
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h6"
                                className={`text-uppercase mb-0 ${
                                  pipeStatus == 9
                                    ? 'isSelectedFilterText'
                                    : 'text-muted'
                                }`}
                              >
                                Pagamento Devolvido
                              </CardTitle>
                              <span
                                className={`h2 font-weight-bold mb-0 ${
                                  pipeStatus == 9 && 'isSelectedFilterText'
                                }`}
                              >
                                {paymentReturned}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </a>
                  </Col>
                  {hasFgts && (
                    <>
                      {/* <Col lg="6" xl="3" className="my-2">
                        <a
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => handleOnClickCard(12, null)}
                        >
                          <Card
                            className={`card-stats mb-4 mb-xl-0 ${
                              pipeStatus == 12 && 'isSelectedFilter'
                            }`}
                          >
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <CardTitle
                                    tag="h6"
                                    className={`text-uppercase mb-0 ${
                                      pipeStatus == 12
                                        ? 'isSelectedFilterText'
                                        : 'text-muted'
                                    }`}
                                  >
                                    Recusado
                                  </CardTitle>
                                  <span
                                    className={`h2 font-weight-bold mb-0 ${
                                      pipeStatus == 12 && 'isSelectedFilterText'
                                    }`}
                                  >
                                    {refused}
                                  </span>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </a>
                      </Col> */}
                      {/* <Col lg="6" xl="3" className="my-2">
                        <a style={{ cursor: 'pointer' }} onClick={(e) => handleOnClickCard(13, null)}>
                          <Card className={`card-stats mb-4 mb-xl-0 ${ pipeStatus == 13 && "isSelectedFilter"}`}>
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <CardTitle
                                    tag="h6"
                                    className={`text-uppercase mb-0 ${ pipeStatus == 13 ? "isSelectedFilterText" : 'text-muted'}`}
                                  >
                                    Reanálise
                                  </CardTitle>
                                  <span className={`h2 font-weight-bold mb-0 ${ pipeStatus == 13 && "isSelectedFilterText"}`}>
                                    {reanalysis}
                                  </span>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </a>
                      </Col> */}
                    </>
                  )}
                </Row>
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
