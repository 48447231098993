import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  Table,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  CustomInput,
} from 'reactstrap';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { InputRange } from '../../components/InputRange';
import { AuthContext } from '../../context/AuthContext';
import token from '../../util/setAuthToken';
import moment from 'moment';
import api from '../../services/api';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda, formatCurrency } from '../../util/mask';
import { VisibilityByUserRoles } from '../../util/visibility';
import { useLocation } from 'react-router-dom';
import TableSimulationFgts from 'components/SimulationFgts/table';

let sendTime = null;
let counterFgts = 0;

const CreateCustomerServiceFgts = (props) => {
  const query = new URLSearchParams(useLocation().search);
  const { register, handleSubmit, errors, setError } = useForm();
  const [creditLimitWithoutIof, setCreditLimitWithoutIof] = useState(null);
  const [fgtsCommissionId, setFgtsCommissionId] = useState(null);
  const [diffIof, setDiffIof] = useState(null);
  const [installmentsSize, setInstallmentsSize] = useState(10);
  const [totalInstallmentsSize, setTotalInstallmentsSize] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [valueFree, setValueFree] = useState(null);
  const [fullValue, setFullValue] = useState(false);
  const [minRate, setMinRate] = useState(null);
  const [disabledButtonAttendance, setDisabledButtonAttendance] =
    useState(false);
  const [maxRate, setMaxRate] = useState(null);
  const [maxValueCdc, setMaxValueCdc] = useState(0);
  const [rateStep, setRateStep] = useState(null);
  const [kindSimulation, setKindSimulation] = useState(null);
  const [minCommission, setMinCommission] = useState(null);
  const [maxCommission, setMaxCommission] = useState(null);
  const [stepCommission, setStepCommission] = useState(null);
  const [fgtsBasicInterests, setFgtsBasicInterests] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [deadlineDisabled, setDeadlineDisabled] = useState(true);
  const [simulationId, setSimulationId] = useState(null);
  const [webhookId, setWebhookId] = useState(null);
  const [simulations, setSimulations] = useState([]);
  const [tacs, setTacs] = useState([]);
  const [tacValue, setTacValue] = useState();
  const [parcels, setParcels] = useState([]);
  const [simulationData, setSimulationData] = useState([]);
  const [simulationDataFgts, setSimulationDataFgts] = useState(null);
  const [selectedSimulations, setSelectedSimulations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSimulation, setLoadingSimulation] = useState(false);
  const [deadline, setDeadline] = React.useState([1]);
  const [rate, setRate] = React.useState(null);
  const [commissionSeller, setCommissionSeller] = React.useState(0);
  const [hasSmart, setHasSmart] = React.useState(false);
  const [commissionSellerPercent, setCommissionSellerPercent] =
    React.useState(0);
  const [minValue, setMinValue] = React.useState(100);
  const [maxValue, setMaxValue] = React.useState(29000);
  const [maxValue1, setMaxValue1] = React.useState(23000);
  const [maxValue2, setMaxValue2] = React.useState(19000);
  const [liquidValue, setLiquidValue] = React.useState(0);
  const [bonusSeller, setBonusSeller] = React.useState(null);
  const [commission, setCommission] = React.useState(null);
  const [tacPercent, setTacPercent] = React.useState(null);
  const [stores, setStores] = React.useState([]);
  const [store, setStore] = React.useState(null);
  const [sellers, setSellers] = React.useState([]);
  const [seller, setSeller] = React.useState(null);
  const [selectedDataFgts, setSelectedDataFgts] = React.useState(null);
  const [fgtsCommissions, setFgtsCommissions] = React.useState([]);
  const [attendance, setAttendance] = useState({
    name: '',
    email: '',
    birth_date: '',
    released_amount: '',
    cpf: '',
    phone: '',
    deadline: null,
    value_free: null,
    value_client: null,
    value_installment: null,
    card_limit: null,
    iof_value: null,
    date_credit: moment().format('YYYY-MM-DD'),
    date_first_installment: moment().add(30, 'days').format('YYYY-MM-DD'),
    phone_store: '',
    phone_seller: '',
  });
  const [storeName, setStoreName] = useState('');
  const [hasCampaign, setHasCampaign] = useState(false);
  const [fgtsNormal, setFgtsNormal] = useState([
    {
      name: 'Ligth',
      name_cdc: 'Tabela Light CDC',
      code: ['200058', '200055'],
      maxValue: 10000000000,
      minValue: 100,
    },
    {
      name: 'Money',
      name_cdc: 'Tabela Gold CDC',
      code: ['200056'],
      maxValue: 10000000000,
      minValue: 100,
    },
    {
      name: 'Cash',
      name_cdc: 'Tabela Gold CDC',
      code: ['200057', '200062', '200063'],
      maxValue: 10000000000,
      minValue: 100,
    },
    {
      name: 'Gold',
      name_cdc: 'Tabela Gold CDC',
      code: ['200059', '200064'],
      maxValue: 10000000000,
      minValue: 100,
    },
    {
      name: 'Diamante',
      name_cdc: 'Tabela Gold CDC',
      code: ['200060', '200065'],
      maxValue: 10000000000,
      minValue: 100,
    }
  ]);
  const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);


  const fgtsCdc = [
    {
      name: 'Money +',
      name_cdc: 'Tabela Light CDC',
      tac: 12,
      maxValue: 29000,
      minValue: 250,
    },
    {
      name: 'Gold +',
      name_cdc: 'Tabela Gold CDC',
      tac: 18,
      maxValue: 19000,
      minValue: 250,
    },
  ];

  const getFgtsParams = () => {
    if (query.get('isCdc')) {
      return fgtsCdc;
    }
    return fgtsNormal;
  };

  const getTac = (value) => {
    if (value > 0 && value <= 250) {
      return 55;
    } else if (value > 250 && value <= 400) {
      return 85;
    } else if (value > 400 && value <= 600) {
      return 125;
    } else if (value > 600 && value <= 800) {
      return 165;
    } else if (value > 800 && value <= 1000) {
      return 205;
    } else if (value > 1000 && value <= 1500) {
      return 205;
    } else {
      return 205;
    }
  };

  async function getSystemSettings() {
    showLoader();

    try {
      const response = await api.get(`system_settings`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      console.log(response.data);
      setHasCampaign(response.data.has_campaign);

      if(response.data.has_campaign){
        setFgtsNormal([...fgtsNormal, {
          name: 'Campanha',
          name_cdc: 'Tabela Gold CDC',
          code: ['200061', '200066'],
          maxValue: 10000000000,
          minValue: 100,
        }])
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os Juros Básicos.');
    }
  
  }

  const getSimulatorSetting = async () => {
    try {
      let { data } = await api.get(
        `simulator_settings/fgts?${seller ? `seller_id=${seller}` : ''}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (data) {
        // setFgtsBasicInterests(data)
        setMinRate(parseFloat(data.min_rate));
        setMaxRate(parseFloat(data.max_rate));
        setRate([parseFloat(data.max_rate)]);
        // setMinCommission(parseFloat(data.min_commission))
        // setMaxCommission(parseFloat(data.max_commission))
        // setCommission([parseFloat(data.min_commission)])
        // const commissionStep = parseFloat(data.max_commission) - parseFloat(data.min_commission)
        setRateStep(parseFloat(data.max_rate) - parseFloat(data.min_rate));

        // setStepCommission(commissionStep/rateStep)
        // setKindSelection('value_free')
      }
    } catch (e) {
      console.log(e);
      console.log('Ops, houve um erro na busca simulator config');
    }
  };

  useEffect(() => {
    if (user && user.roles && user.roles[0].name == 'master_store') {
      setStore(user.store_id);
      getSellers(user.store_id);
    }

    if (user && user.roles && user.roles[0].name == 'user_seller') {
      getSimulatorSetting();
      getFgtsEnabled();
    }

    if (user && user.roles && user.roles[0].name == 'master') {
      setDeadlineDisabled(false);
    }
  }, [user]);

  // useEffect(() => {
  //   getWebhook(query.get("webhook"))
  // }, [])

  useEffect(() => {
    if (seller) {
      getSimulatorSetting();
      getSystemSettings()
      getFgtsEnabled();
    }
  }, [seller]);

  async function getStore() {
    try {
      const { data } = await api.get('stores/searchs', {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (data) {
        setStores(data.sort((a, b) => sortByText(a, b, 'ASC')));
      }
    } catch (error) {
      console.log('Algo deu errado ao buscar os Lojas');
    }
  }

  const sortByText = (a, b, order = 'ASC') => {
    const diff = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

    if (order === 'ASC') {
      return diff;
    }

    return -1 * diff;
  };

  async function getSellers(store_id) {
    try {
      const { data } = await api.get(
        `sellers/searchs?q[store_id_eq]=${store_id}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      if (data) {
        setSellers(data.sort((a, b) => sortByText(a, b, 'ASC')));
      }
    } catch (error) {
      console.log('Algo deu errado ao buscar os parceiros');
    }
  }

  function parseMoney(value) {
    if (value) {
      return value.toFixed(2).toString().replace('.', ',');
    } else {
      return 0;
    }
  }

  function getPercentValue(value, total) {
    if (value == 0 || value == null) {
      return 0;
    }
    if (total == 0 || total == null) {
      return 0;
    }
    return parseMaskCurrency(((100 * value) / parseCurrency(total)).toFixed(2));
  }

  const hasSecureMethod = (convenioCodeParams) => {
    return ['200029', '200030', '200031', '200032', '200033'].includes(
      convenioCodeParams
    );
  };

  const secureCodes = [
    '200029',
    '200030',
    '200031',
    '200032',
    '200033',
    '200035',
    '200036',
  ];

  const getConvenioCode = (tac) => {
    if ([30].includes(tac)) {
      // if (query.get('isCdc')) {
      //   return ['200025', '200034', '200035', '200036'];
      // }

      return [
        '200022',
        '200029',
        '200023',
        '200030',
        '200024',
        '200031',
        '200034',
        '200036',
        '200026',
        '200032',
        '200025',
        '200027',
        '200033',
        '200035',
      ];
    } else {
      if ([12].includes(tac)) {
        if (query.get('isCdc')) {
          return ['200034', '200036'];
        }

        return [
          '200022',
          '200029',
          '200023',
          '200030',
          '200024',
          '200031',
          '200034',
          '200036',
        ];
      } else if ([15].includes(tac)) {
        return ['200026', '200032'];
      } else if ([18].includes(tac)) {
        if (query.get('isCdc')) {
          return ['200025', '200035'];
        }

        return ['200025', '200027', '200033', '200035'];
      }
    }
  };

  async function getCommissionSeller(
    value,
    rate,
    tac = tacPercent,
    has_secure = false
  ) {
    clearTimeout(sendTime);
    setLoading(true);
    sendTime = setTimeout(async function () {
      try {
        let { data } = await api.post(
          `simulations/commissions_fgts?${seller ? `seller_id=${seller}` : ''}`,
          { value, rate: rate, tac: tac, has_secure: has_secure },
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        console.log(data);

        if (data) {
          setCommissionSeller(data.commission_seller_value);
          setCommissionSellerPercent(data.commission_seller_percent);
          if (
            data.commission_seller_trigger_value &&
            data.commission_seller_trigger_value != 0
          ) {
            setBonusSeller(data.commission_seller_trigger_value);
          } else {
            setBonusSeller(null);
          }
        }
      } catch (error) {
        console.log(error);
        let message = '';

        if (error.response.data.errors) {
          message = error.response.data.errors;
          message.forEach((e) => {
            toastError(e.msg);
          });
        } else if (error.response.data) {
          message = error.response.data.message;
        } else {
          message =
            'Ops, tivemos um erro na atualização dos dados, por favor tente novamente!';
        }

        toastError(message);
      }
      setLoading(false);
    }, 1000);
  }

  const selectSimulation = (simulation, checked) => {
    // if(checked) {
    //   setSelectedSimulations(simulation)
    // }else{
    //   setSelectedSimulations(null)
    // }

    if (checked) {
      setSelectedSimulations([...selectedSimulations, simulation]);
    } else {
      setSelectedSimulations(
        selectedSimulations.filter((item) => item.index != simulation.index)
      );
    }
  };

  async function getBalance(
    valueFree = null,
    installments = null,
    rate = 2.04,
    changeInstallment = true,
    tac = [30],
    valueClient = null
  ) {
    console.log(valueFree);
    console.log(valueClient);

    if (query.get('isCdc')) {
      valueFree = valueFree / 0.9;
      valueFree += valueClient;
      valueFree = parseFloat(valueFree.toFixed(2));
      if (valueFree > maxValueCdc) {
        return toastError(
          `O valor liberado deve ser menor ou igual a ${maxValueCdc}`
        );
      }
    }

    if (attendance.cpf) {
      showLoader();
      console.log('PADD');
      console.log(tac);
      try {
        if (valueFree) {
          setDisabledButtonAttendance(true);
          setFullValue(true);
        } else {
          setFullValue(false);
        }

        let { data } = await api.post(
          `simulations/balance_fgts?${seller ? `seller_id=${seller}` : ''}`,
          {
            cpf: attendance.cpf,
            rate: rate,
            installments: installments,
            value_free: valueFree,
            birth_date: '1980-05-20',
            tac: tac[0],
          },
          {
            headers: {
              Authorization: `Bearer ${token()}`,
              Application: `nsaque-front-end`,
            },
          }
        );

        // TODO: Remover quando homologação estiver ok
        data["CondicoesCredito"]["ListaCondicaoCredito"] = data["CondicoesCredito"]["ListaCondicaoCredito"].filter(item => item.Despesas.ListaDespesa.length > 0)

        let arrayValues = data.CondicoesCredito.ListaCondicaoCredito.map(
          (item) => item.ValorSolicitado
        ).sort();

        if (!maxValueCdc) {
          setMaxValueCdc(arrayValues[arrayValues.length - 1]);
        }
        setDeadline([
          data.CondicoesCredito.ListaCondicaoCredito[0].Parcelas.ListaParcela
            .length,
        ]);
        setInstallmentsSize(
          data.CondicoesCredito.ListaCondicaoCredito[0].Parcelas.ListaParcela
            .length
        );
        setTotalInstallmentsSize(data.ParcelasFGTS.ListaPeriodoSaque.length);
        setTacs([]);
        setSelectedDataFgts(null);
        setTacValue(
          data.CondicoesCredito.ListaCondicaoCredito[0].Despesas.ListaDespesa[0]
            .Valor
        );
        setSimulationDataFgts(data);
        setLiquidValue(
          data.CondicoesCredito.ListaCondicaoCredito[0].ValorCliente
        );

        // getCommissionSeller(
        //   data.CondicoesCredito.ListaCondicaoCredito[0].ValorCliente,
        //   tac[0]
        // );
        if (!query.get('isCdc')) {
          setAttendance({ ...attendance, value_free: null });
        }
        hideLoader();
      } catch (error) {
        hideLoader();
        setDisabledButtonAttendance(false);
        console.log('DEU ERRO');
        console.log(error);
        let message = '';

        if (error.response?.data?.errors) {
          message = error.response.data.errors;
          message.forEach((e) => {
            toastError(e.msg);
          });
        } else if (error?.response?.data?.data) {
          message = error.response?.data?.data?.message;
        } else if (error?.response?.data) {
          message = error.response.data.message;
        } else {
          message = 'Ops, tivemos um erro ao efetuar simulação!';
        }

        toastError(message);
        window.scrollTo(0, 0);
      }
    }
  }

  function touchEnd() {
    console.log('SOLTANDO');
  }

  function handleOnChange(e) {
    if (e.target.name === 'cpf') {
      if (e.target.value === '' || e.target.value === '___.___.___-__') {
        setError('cpf', 'CPF obrigatório');
      } else if (!isValidCpf(e.target.value)) {
        setError('cpf', 'CPF inválido');
      } else {
        setError('cpf', '');
      }
    }
    setAttendance({ ...attendance, [e.target.name]: e.target.value });
  }

  function setKindSelection(value) {
    setAttendance({
      ...attendance,
      value_free: 0,
      value_installment: 0,
      card_limit: 0,
    });
    setCommissionSeller(0);
    setBonusSeller(null);
    setKindSimulation(value);
  }

  function registerCustomer(attendanceId, isCdc = false) {
    if (customerId) {
      swal({
        title: 'Atendimento',
        text: 'Deseja finalizar o cadastro do cliente?',
        icon: 'success',
        buttons: ['Não', 'Sim'],
        dangerMode: true,
      }).then(async (next) => {
        if (next) {
          props.history.push(
            `/admin/customers/${customerId}?customerServiceId=${attendanceId}&kind=fgts${
              isCdc ? '&isCdc=true' : ''
            }`
          );
          // props.history.push(`/admin/contracts/create?customerServiceId=${attendanceId}`);
        } else {
          props.history.push('/admin/customer-services');
        }
      });
    } else {
      swal({
        title: 'Atendimento',
        text: 'Deseja finalizar o cadastro do cliente?',
        icon: 'success',
        buttons: ['Não', 'Sim'],
        dangerMode: true,
      }).then(async (next) => {
        if (next) {
          props.history.push(
            `/admin/customers/create?customerServiceId=${attendanceId}&kind=fgts${
              isCdc ? '&isCdc=true' : ''
            }`
          );
        } else {
          props.history.push('/admin/customer-services');
        }
      });
    }
  }

  function handleChangeRate(values) {
    setLoadingSimulation(true);
    console.log(minRate);
    console.log(maxRate);
    console.log(values);
    setRate(values);
    clearTimeout(sendTime);
    sendTime = setTimeout(async function () {
      // handleValueRange(rate[0], values)
      const valueFree = parseCurrency(attendance.value_free) || null;
      getBalance(valueFree, deadline[0], values[0], false, tacs);
    }, 1000);
  }

  function handleChangeTac(
    convenioCode,
    dataFgts,
    tac,
    fgts_commission_id = null
  ) {
    console.log(convenioCode);
    let valueCalc = dataFgts.ValorSolicitado;
    setFgtsCommissionId(fgts_commission_id);
    if (query.get('isCdc')) {
      valueCalc = dataFgts.ValorCliente;
    }

    setSelectedDataFgts(dataFgts);
    if (!attendance.value_client) {
      setAttendance({ ...attendance, value_free: valueCalc });
    }
    setTacs(convenioCode);

    const hasSecure = dataFgts?.Despesas?.ListaDespesa.filter(
      (item) => item.CodigoTipo == 2
    )[0]
      ? true
      : false;

    getCommissionSeller(
      valueCalc,
      dataFgts.TaxaCLAM.toFixed(2),
      tac,
      hasSecure
    );
    // setLoadingSimulation(true);
    // console.log(minRate);
    // console.log(maxRate);
    // console.log(values);
    // clearTimeout(sendTime);
    // sendTime = setTimeout(async function () {
    //   // handleValueRange(rate[0], values)
    //   const valueFree = parseCurrency(attendance.value_free) || null;
    //   getBalance(valueFree, deadline[0], rate[0], false, values);
    // }, 1000);
  }

  function handleChangeValue(e) {
    setAttendance({ ...attendance, released_amount: e });
  }

  // function handleChangeCommission(values) {
  //   setCommission(values)
  // }

  function isValidCpf(cpf) {
    // Remover a máscara, mantendo apenas os dígitos
    cpf = cpf.replace(/[^\d]/g, '');

    // Verificar se o CPF tem 11 dígitos
    if (cpf.length !== 11) {
      return false;
    }

    // Verificar se todos os dígitos são iguais (caso contrário, não é válido)
    if (/^(\d)\1{10}$/.test(cpf)) {
      return false;
    }

    // Calcular o primeiro dígito verificador
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let remainder = sum % 11;
    let digit1 = remainder < 2 ? 0 : 11 - remainder;

    // Verificar o primeiro dígito verificador
    if (parseInt(cpf.charAt(9)) !== digit1) {
      return false;
    }

    // Calcular o segundo dígito verificador
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = sum % 11;
    let digit2 = remainder < 2 ? 0 : 11 - remainder;

    // Verificar o segundo dígito verificador
    if (parseInt(cpf.charAt(10)) !== digit2) {
      return false;
    }

    return true; // O CPF é válido
  }

  async function handleOnBlurCpf(event) {
    const { value } = event.target;

    if (value.length > 0) {
      const cpf = value.replace(/[^0-9]+/g, '');

      try {
        let { data } = await api.get(
          `contracts/get_customer_by_cpf?q[entity_cpf_cnpj_eq]=${cpf}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        if (data.length > 0) {
          data = data[0];
          console.log(data);
        }

        setCustomerId(data.id);

        setAttendance({
          ...attendance,
          name: data.name,
          phone: data.mobile,
          email: data.email,
        });
      } catch (e) {
        console.log(e);
        console.log('Ops, houve um erro na busca pelo CPF');
      }
    }
  }

  function parseCurrency(value) {
    if (typeof value === 'string' || value instanceof String) {
      return Number(value.replace('.', '').replace(',', '.').replace('R$', ''));
    } else {
      return value;
    }
  }

  function parseMaskCurrency(value) {
    return value.toString().replace('.', ',');
  }

  async function handleOnChangeValueFree(e) {
    console.log(e);

    let value_client = attendance?.value_client || 0;

    setAttendance({
      ...attendance,
      value_free: e,
    });
  }

  async function handleOnChangeValueClient(e) {
    let value_free = parseCurrency(attendance?.value_free) || 0;
    let value_client = parseCurrency(e);
    if (value_free >= value_client) {
      console.log(value_free - value_client);
    } else {
      console.log(value_client - value_free);
    }
    setAttendance({
      ...attendance,
      value_client: e,
    });
  }

  function handleChangeDeadline(values) {
    // setDeadline(values)
    // setLoadingSimulation(true)
    // clearTimeout(sendTime)
    // sendTime = setTimeout(async function() {
    //   // handleValueRange(rate[0], values)
    // }, 1000)

    setLoadingSimulation(true);
    setDeadline(values);
    clearTimeout(sendTime);
    sendTime = setTimeout(async function () {
      // handleValueRange(rate[0], values)
      const valueFree = parseCurrency(attendance.value_free) || null;
      getBalance(valueFree, values[0], deadline[0], false, tacs);
    }, 1000);
  }

  async function handleCheckBalance(cpf) {
    try {
      let { data } = await api.post(
        `simulations/check_cpf_balance_fgts`,
        {
          cpf: cpf,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
            Application: `nsaque-front-end`,
          },
        }
      );

      if (!data.data.cpf) {
        setAttendance({
          ...attendance,
          value_free: null,
        });
        setDisabledButtonAttendance(false);
        getBalance();
      }
    } catch {
      swal({
        title: 'Atendimento',
        text: 'O cliente foi consultado e não tinha autorizado a instituição financeira. O cliente já autorizou?',
        icon: 'warning',
        buttons: ['Não', 'Sim'],
        dangerMode: true,
      }).then(async (next) => {
        if (next) {
          setAttendance({
            ...attendance,
            value_free: null,
          });
          setDisabledButtonAttendance(false);
          getBalance();
        } else {
          console.log('Não');
        }
      });
    }
  }

  function handleTableNamePerTac(tac) {
    let tableName = '';
    switch (tac) {
      case tac === 12:
        tableName = 'Money';
        break;
      case tac === 15:
        tableName = 'Laranja';
        break;
      case tac === 18:
        tableName = 'Gold';
        break;
      case tac === 30:
        tableName = 'Smart';
        break;
      default:
        tableName = 'Money';
    }
    return tableName;
  }

  async function onSubmit(event) {
    localStorage.setItem('fgts_store_name', event.store);
    if (liquidValue > maxValue) {
      hideLoader();

      toastError(
        `O valor máximo liberado é de ${maxValue.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}`
      );

      return;
    }

    if (attendance.value_free < minValue) {
      hideLoader();

      toastError(
        `O valor minimo liberado é de ${minValue.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}`
      );

      return;
    }

    if (query.get('isCdc')) {
      const valueFree =
        parseCurrency(attendance?.value_client) +
        parseCurrency(attendance?.value_free);

      if (valueFree > maxValueCdc) {
        return toastError(
          `O valor liberado deve ser menor ou igual a ${maxValueCdc}`
        );
      }
    }

    let installments = selectedDataFgts.Parcelas?.ListaParcela.map((item) => {
      return {
        total_amount: item.ValorParcela,
        // release_amount: item.ValorParcela - item.finTax/100 - item.tc/100,
        release_amount: item.ValorParcela,
        due_date: item.DataVencimento,
      };
    });

    showLoader();
    console.log(selectedDataFgts);
    console.log(selectedDataFgts.TaxaCLAM);
    console.log(selectedDataFgts.TaxaCLAM.toFixed(2));
    try {
      let data = {
        simulation: {
          name: event.name,
          interest_rate: parseFloat(selectedDataFgts.TaxaCLAM).toFixed(2),
          email: event.email,
          cpf_cnpj: event.cpf,
          phone: event.phone,
          fgts_commission_id: fgtsCommissionId,
          phone_store: event.phone_store,
          phone_seller: event.phone_seller,
          full_value: fullValue,
          // tac: simulationDataFgts[`${deadline[0] - 1}`].tc/100,
          tac_table_name: handleTableNamePerTac(tacPercent),
          tac_percent: tacPercent,
          tac: selectedDataFgts.Despesas.ListaDespesa.filter(
            (item) => item.Valor != 0
          )[0].Valor,
          parcels: JSON.stringify(installments),
          simulation_fgts: JSON.stringify(selectedDataFgts),
          simulation_json: JSON.stringify(simulationDataFgts),
          has_secure: selectedDataFgts?.Despesas?.ListaDespesa.filter(
            (item) => item.CodigoTipo == 2
          )[0]
            ? true
            : false,
          liquid_value: selectedDataFgts.ValorCliente,
          released_amount: selectedDataFgts.ValorSolicitado,
          value_client: parseCurrency(attendance?.value_client),
          value_establishment: parseCurrency(attendance?.value_free),
          // card_limit: parseCurrency(parcels[`${deadline[0] - 1}`].ValorParcela),
          card_limit: selectedDataFgts.Parcelas?.ListaParcela.map(
            (item) => item.ValorParcela
          ).reduce(function (a, b) {
            return a + b;
          }),
          value_installment: parseCurrency(installments[0].total_amount),
          installments: deadline[0],
          birth_date: '1980-05-20',
        },
        kind: query.get('isCdc') ? 'cdc' : 'fgts',
      };

      if (customerId) {
        data = {
          simulation: {
            ...data.simulation,
            customer_id: customerId,
            status: 1,
          },
          kind: query.get('isCdc') ? 'cdc' : 'fgts',
        };
      }

      if (seller) {
        data = { ...data, seller_id: seller };
      }

      const response = await api.post(
        'simulations/create_fgts',
        JSON.stringify(data),
        {
          headers: {
            Application: `nsaque-front-end`,
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      console.log(data);
      hideLoader();

      toastSuccess('Dados atualizado com sucesso.');
      console.log(response.data);
      registerCustomer(response.data.simulation.id, query.get('isCdc'));
    } catch (error) {
      hideLoader();
      console.log(error);

      let message = '';

      if (error.response.data.errors) {
        message = error.response.data.errors;
        message.forEach((e) => {
          toastError(e.msg);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro na atualização dos dados, por favor tente novamente!';
      }

      toastError(message);
      window.scrollTo(0, 0);
    }
  }

  async function handleOnChangeStore(e) {
    setStoreName(e.target.value);
    if (e.target.value.length > 4) {
      let url_stores = `/stores/searchs?q[entity_name_cont]=${e.target.value}`;
      if (query.get('isCdc')) {
        url_stores += `&q[code_qista_not_null]=1`;
      }
      const response = await api.get(url_stores, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      const values = response.data.map((value, index) => {
        return {
          value: `${value.id} - ${value.name}`,
          label: `${value.id} - ${value.name}`,
          mobile: value.mobile,
        };
      });
      setStores(values);
    } else if (e.target.value === '') {
      setStores([]);
    }
  }

  async function getFgtsEnabled() {
    const response = await api.get(
      `/simulations/get_fgts_enabled?${seller ? `seller_id=${seller}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );

    setFgtsCommissions(response.data);

    console.log(response);
  }

  useEffect(() => {
    const sellerSelected = sellers.filter((item) => item.id === Number(seller));
    if (sellerSelected.length > 0) {
      setAttendance((prevState) => ({
        ...prevState,
        phone_seller: sellerSelected[0].mobile,
      }));
    }
  }, [seller]);

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Novo Atendimento</h3>
                    <h5 className="badge badge-warning">
                      Autorizar no App Meu FGTS: Qista S.A - Crédito
                      Financiamento e Investimento
                    </h5>
                    <br />

                    {query.get('isCdc') && (
                      <h5 className="badge badge-success">
                        NOVA PROPOSTA CDC FGTS
                      </h5>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações pessoais
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            CPF
                          </label>
                          <InputMask
                            type="text"
                            name="cpf"
                            // placeholder="CPF"
                            className="form-control form-control-alternative"
                            id="cpf"
                            // placeholder="999.999.999-99"
                            mask="999.999.999-99"
                            onBlur={handleOnBlurCpf}
                            value={attendance.cpf}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.cpf && (
                            <small className="text-danger">
                              {Object.values(errors.cpf)
                                .reduce((t, n) => t + n, '')
                                .split('[')[0] === 'required'
                                ? 'CPF é obrigatório'
                                : `${
                                    Object.values(errors.cpf)
                                      .reduce((t, n) => t + n, '')
                                      .split('[')[0]
                                  }`}
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Nome
                          </label>
                          <InputMask
                            type="text"
                            name="name"
                            // placeholder="Nome"
                            className="form-control form-control-alternative"
                            id="name"
                            value={attendance.name}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.name && (
                            <small className="text-danger">
                              O nome é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Email
                          </label>
                          <InputMask
                            type="text"
                            name="email"
                            // placeholder="Email"
                            className="form-control form-control-alternative"
                            id="email"
                            value={attendance.email}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.email && (
                            <small className="text-danger">
                              O email é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="phone">
                            Telefone
                          </label>
                          <InputMask
                            type="text"
                            name="phone"
                            // placeholder="Celular"
                            className="form-control form-control-alternative"
                            id="phone"
                            mask="(99) 99999-9999"
                            value={attendance.phone}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.phone && (
                            <small className="text-danger">
                              O celular é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      {/* <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="birth_date"
                            >
                              Data de nascimento
                            </label>
                            <input
                              type="date"
                              name="birth_date"
                              className="form-control form-control-alternative"
                              id="birth_date"
                              value={attendance && attendance.birth_date}
                              ref={register({ required: true })}
                              onChange={handleOnChange}
                            />
                            {errors.birth_date && (
                              <small className="text-danger">
                                A data de nascimento é obrigatória
                              </small>
                            )}
                          </FormGroup>
                        </Col> */}
                      {(!user?.corban_id ||
                        (user?.corban_id && user?.has_proposed_permission)) && (
                        <>
                          <VisibilityByUserRoles
                            roles={[
                              'master',
                              'operational',
                              'master_corban',
                              'user_corban',
                              'regional_manager',
                              'commercial_manager',
                            ]}
                          >
                            <Col md="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="store"
                                >
                                  {query.get('isCdc')
                                    ? 'Estabelecimento'
                                    : 'Loja'}
                                </label>
                                <input
                                  className="form-control form-control-alternative"
                                  id="store"
                                  name="store"
                                  value={storeName}
                                  ref={register({ required: true })}
                                  onChange={handleOnChangeStore}
                                  autoComplete="off"
                                />
                                <ul className="corban-list">
                                  {stores &&
                                    stores.map((store) => (
                                      <li
                                        key={store.id}
                                        className="item-list"
                                        onClick={() => {
                                          setStores([]);
                                          setStoreName(store.value);
                                          getSellers(
                                            store.value.match(/^\d+/)?.[0]
                                          );
                                          setStore(
                                            store.value.match(/^\d+/)?.[0]
                                          );
                                          setAttendance((prevState) => ({
                                            ...prevState,
                                            phone_store: store.mobile,
                                          }));
                                        }}
                                      >
                                        {store.value}
                                      </li>
                                    ))}
                                </ul>
                                {errors.store && (
                                  <small className="text-danger">
                                    Loja é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="phone-store"
                                >
                                  Telefone Loja
                                </label>
                                <InputMask
                                  type="text"
                                  name="phone_store"
                                  placeholder="Digite o número da loja"
                                  className="form-control form-control-alternative"
                                  id="phone_store"
                                  mask="(99) 99999-9999"
                                  value={attendance.phone_store}
                                  inputRef={register({ required: true })}
                                  onChange={handleOnChange}
                                />
                                {errors.phone_store && (
                                  <small className="text-danger">
                                    O número da loja é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          </VisibilityByUserRoles>
                          {/* </Row>
                      <Row> */}
                          <VisibilityByUserRoles
                            roles={[
                              'master',
                              'operational',
                              'master_corban',
                              'user_corban',
                              'master_store',
                              'regional_manager',
                              'commercial_manager',
                            ]}
                          >
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="seller"
                                >
                                  Vendedor
                                </label>
                                <CustomInput
                                  type="select"
                                  id="seller"
                                  ref={register({ required: true })}
                                  name="seller"
                                  placeholder="Escolha o Vendedor"
                                  onChange={(e) => setSeller(e.target.value)}
                                  value={seller}
                                >
                                  <option value="">Selecione uma opção</option>
                                  {sellers.map((el) => (
                                    <option key={el.id} value={el.id}>
                                      {el.name}
                                    </option>
                                  ))}
                                </CustomInput>
                                {errors.store && (
                                  <small className="text-danger">
                                    O vendedor é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="phone"
                                >
                                  Telefone Vendedor
                                </label>
                                <InputMask
                                  type="text"
                                  name="phone_seller"
                                  placeholder="Digite o número do vendedor"
                                  className="form-control form-control-alternative"
                                  id="phone_seller"
                                  mask="(99) 99999-9999"
                                  value={attendance.phone_seller}
                                  inputRef={register({ required: true })}
                                  onChange={handleOnChange}
                                />
                                {errors.phone_seller && (
                                  <small className="text-danger">
                                    O número do vendedor é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          </VisibilityByUserRoles>
                        </>
                      )}
                    </Row>
                  </div>
                  {rate && attendance.cpf && (
                    <div className="text-right my-1">
                      <Button
                        disabled={loading}
                        type="button"
                        onClick={() => handleCheckBalance(attendance.cpf)}
                        className="btn btn-success px-4"
                      >
                        <i className="fas fa-paper-plane" /> Consultar Saldo
                      </Button>
                    </div>
                  )}

                  <hr />
                  {rate && attendance.cpf && parcels && simulationDataFgts && (
                    <div className="pl-lg-4 ">
                      <Row className="d-flex justify-content-center">
                        <Col lg="4">
                          <FormGroup className="container-custom-simulator">
                            <label
                              className="form-control-label"
                              htmlFor="name"
                            >
                              {query.get('isCdc')
                                ? 'Valor Liberado Estabelecimento'
                                : 'Valor Solicitado(Bruto)'}
                            </label>
                            <CurrencyInput
                              prefix="R$"
                              decimalSeparator=","
                              thousandSeparator="."
                              name="value_free"
                              // placeholder="999.00"
                              className="form-control form-control-alternative"
                              id="value_free"
                              value={attendance.value_free}
                              // inputRef={register({ required: true })}
                              onChange={(e) => handleOnChangeValueFree(e)}
                              // onChange={handleOnChangeValueFree}
                            />
                            {errors.name && (
                              <small className="text-danger">
                                O campo é obrigatório
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                        {/* {query.get('isCdc') && (
                          <Col lg="4">
                            <FormGroup className="container-custom-simulator">
                              <label
                                className="form-control-label"
                                htmlFor="name"
                              >
                                Valor Liberado Cliente
                              </label>
                              <CurrencyInput
                                prefix="R$"
                                decimalSeparator=","
                                thousandSeparator="."
                                name="value_client"
                                // placeholder="999.00"
                                className="form-control form-control-alternative"
                                id="value_client"
                                value={attendance.value_client}
                                // inputRef={register({ required: true })}
                                onChange={(e) => handleOnChangeValueClient(e)}
                                // onChange={handleOnChangeValueFree}
                              />
                              {errors.name && (
                                <small className="text-danger">
                                  O campo é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        )} */}
                        {attendance.value_free && (
                          <div className="text-right mt-4">
                            <Button
                              disabled={loading}
                              type="button"
                              onClick={() =>
                                getBalance(
                                  parseCurrency(attendance.value_free),
                                  deadline[0],
                                  rate[0],
                                  false,
                                  tacs,
                                  parseCurrency(attendance.value_client)
                                )
                              }
                              className="btn btn-success px-4 mt-1"
                            >
                              <i className="fas fa-paper-plane" /> Nova Consulta
                            </Button>
                          </div>
                        )}
                      </Row>
                    </div>
                  )}
                  <Row>
                    {rate && attendance.cpf && parcels && simulationDataFgts && (
                      <Col lg="5 mt-4 ml-4">
                        <Row>
                          <Col>
                            <Row className="d-flex justify-content-center">
                              <h3 className="mb-4">
                                {simulationDataFgts.CondicoesCredito.ListaCondicaoCredito.filter(
                                  (list) => list?.ValorSolicitado >= 250
                                ).length > 0 && (
                                  <>
                                    {getFgtsParams().map((table) => (
                                      <>
                                        {simulationDataFgts.CondicoesCredito.ListaCondicaoCredito.filter(
                                          (list) =>
                                            table.code.includes(
                                              list.Convenio.CodigoConvenio
                                            ) && list?.ValorSolicitado >= 250
                                        ).length > 0 && (
                                          <TableSimulationFgts
                                            isCdc={query.get('isCdc')}
                                            hasSecure={true}
                                            secureCodes={secureCodes}
                                            getConvenioCode={getConvenioCode}
                                            simulationDataFgts={
                                              simulationDataFgts
                                            }
                                            minValue={minValue}
                                            tacs={tacs}
                                            fgtsCommissions={fgtsCommissions}
                                            table={table}
                                            setTacPercent={setTacPercent}
                                            handleChangeTac={handleChangeTac}
                                            lower_than_250={false}
                                          />
                                        )}
                                      </>
                                    ))}
                                  </>
                                )}

                                {query.get('isCdc') && (
                                  <>
                                    {simulationDataFgts.CondicoesCredito.ListaCondicaoCredito.filter(
                                      (list) => (list) =>
                                        list?.ValorSolicitado < 250
                                    ).length > 0 && (
                                      <>
                                        {[
                                          {
                                            name: 'Gold +',
                                            name_cdc: 'Tabela Gold CDC',
                                            tac: 30,
                                            rate: 1.79,
                                            maxValue: 250,
                                            minValue: 100,
                                          },
                                          {
                                            name: 'Money +',
                                            name_cdc: 'Tabela Light CDC',
                                            tac: 30,
                                            rate: 1.55,
                                            maxValue: 250,
                                            minValue: 100,
                                          },
                                        ].map((table) => (
                                          <>
                                            {simulationDataFgts.CondicoesCredito.ListaCondicaoCredito.filter(
                                              (list) =>
                                                getConvenioCode(
                                                  table.tac
                                                ).includes(
                                                  list.Convenio.CodigoConvenio
                                                ) && list?.ValorSolicitado < 250
                                            ).length > 0 && (
                                              <TableSimulationFgts
                                                isCdc={query.get('isCdc')}
                                                hasSecure={true}
                                                secureCodes={secureCodes}
                                                getConvenioCode={
                                                  getConvenioCode
                                                }
                                                simulationDataFgts={
                                                  simulationDataFgts
                                                }
                                                minValue={minValue}
                                                tacs={tacs}
                                                fgtsCommissions={
                                                  fgtsCommissions
                                                }
                                                table={table}
                                                setTacPercent={setTacPercent}
                                                handleChangeTac={
                                                  handleChangeTac
                                                }
                                              />
                                            )}
                                          </>
                                        ))}
                                      </>
                                    )}
                                  </>
                                )}

                                {!query.get('isCdc') &&
                                  simulationDataFgts.CondicoesCredito.ListaCondicaoCredito.filter(
                                    (list) => list?.ValorSolicitado < 250
                                  ).length > 0 && (
                                    <>
                                      {getFgtsParams().map((table) => (
                                        <>          
                                          {simulationDataFgts.CondicoesCredito.ListaCondicaoCredito.filter(
                                            (list) =>
                                              table.code.includes(
                                                list.Convenio.CodigoConvenio
                                              ) && list?.ValorSolicitado < 250
                                          ).length > 0 && (
                                            <TableSimulationFgts
                                              isCdc={query.get('isCdc')}
                                              hasSecure={true}
                                              secureCodes={secureCodes}
                                              getConvenioCode={getConvenioCode}
                                              simulationDataFgts={
                                                simulationDataFgts
                                              }
                                              minValue={minValue}
                                              tacs={tacs}
                                              fgtsCommissions={fgtsCommissions}
                                              table={table}
                                              setTacPercent={setTacPercent}
                                              handleChangeTac={handleChangeTac}
                                              lower_than_250={true}
                                            />
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                              </h3>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {selectedDataFgts && (
                      <Col lg="6 mt-4" className="container-results ml-4">
                        <Row>
                          <Col lg="6 mt-4" className="">
                            {selectedDataFgts.Parcelas?.ListaParcela &&
                              selectedDataFgts.Parcelas?.ListaParcela.length >
                                0 && (
                                <Table className="align-items-center justify-content-center table-flush">
                                  <thead className="">
                                    <tr>
                                      {/* <th scope="col">Parcelas</th> */}
                                      <th scope="col">
                                        Plano de Pagamentos FGTS
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedDataFgts.Parcelas?.ListaParcela.map(
                                      (parcel, index) => (
                                        <tr key={index}>
                                          <td>
                                            {index + 1} - {}
                                            {new Date(
                                              parcel.DataVencimento
                                            ).toLocaleDateString()}
                                          </td>
                                          <td>
                                            {parcel.ValorParcela.toLocaleString(
                                              'pt-br',
                                              {
                                                style: 'currency',
                                                currency: 'BRL',
                                              }
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                    <tr>
                                      <td>
                                        <p className="result-custom-simulator-medium-not-height">
                                          Total
                                        </p>
                                      </td>
                                      <td>
                                        <p className="result-custom-simulator-medium-not-height">
                                          {selectedDataFgts.Parcelas?.ListaParcela.map(
                                            (item) => item.ValorParcela
                                          )
                                            .reduce(function (a, b) {
                                              return a + b;
                                            })
                                            .toLocaleString('pt-br', {
                                              style: 'currency',
                                              currency: 'BRL',
                                            })}
                                        </p>
                                      </td>
                                    </tr>
                                    {/* <tr>
                                        <td><p className='result-custom-simulator-medium-not-height'>Total Retido de Saldo</p></td>
                                        <td><p className='result-custom-simulator-medium-not-height'>{(parcels.slice(0, deadline[0]).map(item => item.ValorParcela).reduce(function(a, b){return a + b;})).toLocaleString('pt-br',{style: 'currency',currency: 'BRL',})}</p></td>
                                      </tr> */}
                                  </tbody>
                                </Table>
                              )}
                          </Col>
                          <Col lg="6 mt-4" className="">
                            <div className="">
                              <div className="title-result-section text-center">
                                Parcelas
                              </div>
                              <div className="value-result-section installment-section text-center justify-content-center">
                                <span className="result-custom-simulator text-center">
                                  {selectedDataFgts?.QuantidadeParcelas}
                                </span>
                              </div>
                            </div>
                            {attendance.value_client && (
                              <div className="mt-4">
                                <div className="title-result-section text-center">
                                  Valor Total
                                </div>
                                <div className="value-result-section justify-content-center">
                                  <CurrencyInput
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    name="value_client"
                                    // placeholder="999.00"
                                    className="form-control result-custom-simulator text-center"
                                    id="value_client"
                                    disabled
                                    value={
                                      (query.get('isCdc') &&
                                      attendance.value_free
                                        ? parseCurrency(attendance.value_free)
                                        : selectedDataFgts.ValorSolicitado) +
                                      parseCurrency(attendance.value_client)
                                    }
                                    // inputRef={register({ required: true })}
                                    // onChange={handleOnChangeValueFree}
                                  />
                                </div>
                              </div>
                            )}

                            <div className="mt-4">
                              <div className="title-result-section text-center">
                                Valor Liberado{' '}
                                {query.get('isCdc') && 'Estabelecimento'}
                              </div>
                              <div className="value-result-section justify-content-center">
                                <CurrencyInput
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  prefix="R$"
                                  name="value_free"
                                  // placeholder="999.00"
                                  className="form-control result-custom-simulator text-center"
                                  id="value_free"
                                  disabled
                                  value={
                                    query.get('isCdc') && attendance.value_free
                                      ? attendance.value_free
                                      : selectedDataFgts.ValorCliente
                                  }
                                  // inputRef={register({ required: true })}
                                  // onChange={handleOnChangeValueFree}
                                />
                              </div>
                            </div>
                            {attendance.value_client && (
                              <div className="mt-4">
                                <div className="title-result-section text-center">
                                  Valor Liberado Cliente
                                </div>
                                <div className="value-result-section justify-content-center">
                                  <CurrencyInput
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    name="value_client"
                                    // placeholder="999.00"
                                    className="form-control result-custom-simulator text-center"
                                    id="value_client"
                                    disabled
                                    value={attendance.value_client}
                                    // inputRef={register({ required: true })}
                                    // onChange={handleOnChangeValueFree}
                                  />
                                </div>
                              </div>
                            )}

                            <Table className="align-items-center justify-content-center table-flush">
                              <tbody>
                                <tr>
                                  <td style={{ padding: 0 }}>
                                    <p className="title-result-section-medium text-center">
                                      Tabela
                                    </p>
                                  </td>
                                  <td style={{ padding: 0 }}>
                                    <p className="title-result-section-medium text-right">
                                      {selectedDataFgts.TaxaCLAM.toFixed(2)}%
                                      a.m. /{' '}
                                      {selectedDataFgts.TaxaAPAA.toFixed(2)}%
                                      a.a.
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: 0 }}>
                                    <p className="title-result-section-medium text-center">
                                      CET
                                    </p>
                                  </td>
                                  <td style={{ padding: 0 }}>
                                    <p className="title-result-section-medium text-right">
                                      {selectedDataFgts.TaxaCETAM.toFixed(2)}%
                                      a.m. /{' '}
                                      {selectedDataFgts.TaxaCETAA.toFixed(2)}%
                                      a.a.
                                    </p>
                                  </td>
                                </tr>
                                {commissionSeller != null &&
                                  !query.get('isCdc') && (
                                    <tr>
                                      <td style={{ padding: 0, fontSize: 18 }}>
                                        <p className="title-result-section-medium text-center text-success">
                                          Comissão
                                        </p>
                                      </td>
                                      <td style={{ padding: 0 }}>
                                        <p className="text-success title-result-section-medium text-right">
                                          {parseFloat(
                                            commissionSeller
                                          ).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL',
                                          })}
                                          (
                                          {getPercentValue(
                                            commissionSeller,
                                            selectedDataFgts.ValorSolicitado
                                          )}
                                          %)
                                        </p>
                                      </td>
                                    </tr>
                                  )}

                                <tr>
                                  <td style={{ padding: 0 }}>
                                    <p className="title-result-section-medium text-orange text-center">
                                      Saldo Retido
                                    </p>
                                  </td>
                                  <td style={{ padding: 0 }}>
                                    <p className="title-result-section-medium text-right text-orange">
                                      {selectedDataFgts.ValorBruto.toLocaleString(
                                        'pt-br',
                                        { style: 'currency', currency: 'BRL' }
                                      )}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>

                  {/* { parcels && parcels.length > 0 ?
                    (<div className="text-right my-4">
                      <Button disabled={loading} type="button" onClick={() => getSimulation()} className="btn btn-success px-4">
                        <i className="fas fa-paper-plane" /> Simular Valor
                      </Button>
                    </div>)
                  :
                    false

                  } */}

                  {selectedDataFgts && (
                    <div className="text-right mt-6">
                      <Button
                        disabled={loading}
                        type="submit"
                        className="btn btn-success px-4"
                      >
                        <i className="fas fa-paper-plane" /> Continuar
                      </Button>
                    </div>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateCustomerServiceFgts;
