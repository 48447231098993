import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from 'reactstrap';

import InputMask from 'react-input-mask';
import UserHeader from '../../components/Headers/UserHeader';
import { AuthContext } from '../../context/AuthContext';
import token from '../../util/setAuthToken';
import api from '../../services/api';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import axios from 'axios';
import CommissionProfile from '../../components/Profile/CommissionProfile'
import EntityProfile from '../../components/Profile/EntityProfile';

import { useForm } from 'react-hook-form';

const ProfileCommission = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [mobile, setMobile] = useState('');
  const [entity, setEntity] = useState('');
  const [address, setAddress] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [commissions, setCommissions] = useState([]);
  const [contactFields, setContactFields] = useState([]);
  const [commissionEntity, setCommissionEntity] = useState(null);

  const { register, handleSubmit, errors } = useForm();


  const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  function handleClickContact() {
    setContactFields([ ...contactFields, { mobile: '', email: '', type_contact: '' }])
  }

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setBirthDate(user.birth_date);
      setMobile(user.mobile);
    }
  }, [user]);

  async function handleSubmitUser(event) {
    event.preventDefault();
    event.stopPropagation();

    showLoader();

    try {
      await api.put(
        'users',
        JSON.stringify({ name, mobile, birth_date: birthDate }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();

      toastSuccess('Dados atualizado com sucesso.');
    } catch (error) {
      hideLoader();

      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.msg);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, não foi possível atualizar seus dados, por favor tente novamente';
      }
      toastError(message);
    }
  }

  async function getEntity(){
    try {
      const response = await api.get(
        'users/get_entity',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();
      setCommissions(response.data.commission_bases_report)

      if(response.data.kind == "subestablishment" || response.data.kind == "store" || response.data.kind == "seller") {
        console.log(response.data.commissions[0])
        setCommissionEntity(response.data.commissions[0])
      }

    } catch (error) {
      console.log(error)
      toastError('Ops, não foi possível atualizar seus dados, por favor tente novamente');
    }
  }

  useEffect(() => {
    getEntity()
  }, [])


  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Dados de Comissão</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {
                  user && user.kind && (user && user.roles[0] && user.roles[0].name != "master" && user.roles[0].name != "operational") && (<CommissionProfile kind={user.kind} commissions={commissions} commissionEntity={commissionEntity}/>)
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProfileCommission;
