import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col
} from 'reactstrap';

import CustomerHeader from '../../components/Headers/CustomerHeader';
import { AuthContext } from '../../context/AuthContext';
import token from '../../util/setAuthToken';
import api from '../../services/api';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import CommissionProfile from '../../components/Profile/CommissionProfile'
import { useParams } from 'react-router-dom'

const Commission = () => {
  const { id } = useParams()
  const [name, setName] = useState('');
  const [commissions, setCommissions] = useState([]);
  const [commissionEntity, setCommissionEntity] = useState(null);

  const { showLoader, hideLoader } = useContext(LoadingContext);


  async function getEntity(){
    try {
      const response = await api.get(
        `entities/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setName(response.data.name)

      hideLoader();
      setCommissions(response.data.commission_bases)

      if(response.data.kind == "subestablishment" || response.data.kind == "store" || response.data.kind == "seller") {
        console.log('aaaa', response.data)
        setCommissionEntity(response.data.commissions[0])
      }

    } catch (error) {
      console.log(error)
      toastError('Ops, não foi possível atualizar seus dados, por favor tente novamente');
    }
  }

  useEffect(() => {
    getEntity()
  }, [])


  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Dados de Comissão { name && `- ${name}` }</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <CommissionProfile kind='store' commissions={commissions} commissionEntity={commissionEntity} entityId={id}  />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Commission;
