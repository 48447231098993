import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import StoreForm from '../../components/StoreForm';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda } from '../../util/mask';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';
import swal from 'sweetalert';

const UpdateEstablishment = (props) => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [corbanName, setCorbanName] = useState('');
  const [fgtsCommissions, setFgtsCommissions] = useState([]);
  const [disabledSubestablishment, setDisabledSubestablishment] =
    useState(false);
  const [store, setStore] = useState({
    social_reason: '',
    name: '',
    cpf_cnpj: '',
    user_cpf: '',
    user_name: '',
    user_email: '',
    user_mobile: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    email: '',
    mobile: '',
    bank_name: '',
    agency_number: '',
    number_account: '',
    has_commission: '',
    commission_type: '',
    active_base_trigger: '',
    value: '',
    trigger: '',
    entity_id: '',
    agency_digit: '',
    account_digit: '',
    internal_code: '',
    support_whats: true,
  });
  const [contactFields, setContactFields] = useState([]);
  const { register, handleSubmit, errors, setError } = useForm();

  const [banks, setBanks] = useState([]);
  const [corbans, setCorbans] = useState([]);
  const [subs, setSubs] = useState([]);
  const [managers, setManagers] = useState([]);

  async function getManagers() {
    showLoader();

    try {
      const response = await api.get(`/users/managers`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      // const values = response.data.map((value,index) => {
      //   return {
      //     value: `${value.id} - ${value.name}`,
      //     label: `${value.id} - ${value.name}`
      //   }
      // })

      setManagers(response.data);

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os managers.');
    }
  }

  async function getSubs(paramsId) {
    // showLoader()

    try {
      const response = await api.get(
        `/subestablishments/searchs?q[corban_id_eq]=${paramsId}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const values = response.data.map((value, index) => {
        return {
          value: `${value.id} - ${value.name}`,
          label: `${value.id} - ${value.name}`,
        };
      });

      console.log(values);
      setSubs([
        {
          value: `0 - Não selecionar`,
          label: `0 - Não selecionar`,
        },
        ...values,
      ]);
      // hideLoader();
    } catch (error) {
      // hideLoader();
      // toastError('Ops, não foi possível lista as corbans.');
    }
  }
  async function getStore(commissions = null) {
    showLoader();
    try {
      const { data } = await api.get(`stores/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      console.log('data', data);
      if (data.corban_id) {
        getSubs(data.corban_id);
      }

      setContactFields(data.contact);

      if (data.fgts_commission && data.fgts_commission.length > 0) {
        // console.log('TEM FGTS MESMO');
        // console.log(data.fgts_commission);
        setFgtsCommissions([
          ...data.fgts_commission.map((item) => {
            return {
              ...item,
              commission_type: item.commission_type == 'percentage' ? '0' : '1',
              value:
                item.commission_type == 'percentage'
                  ? `${item.value}`
                  : `R$ ${item.value}`,
              trigger: '',
            };
          }),
        ]);
      }

      console.log('SALVANDO DADOS', data);

      if (data.corban_name) {
        setCorbanName(data.corban_name);
      } else {
        setCorbanName('');
      }

      setStore({
        id: data.id,
        kind_table: data.kind_table,
        cpf_cnpj: data.entity.cpf_cnpj,
        code_qista: data.code_qista,
        name: data.entity.name,
        commission_fgts: data.entity.commission_fgts,
        social_reason: data.entity.social_reason,
        zip_code: data.address.zip_code,
        store_kind: data.kind == 'pj' ? 0 : 1,
        attendance_method: data.attendance_method,
        street: data.address.street,
        number: data.address.number,
        district: data.address.district,
        city: data.address.city,
        state: data.address.state,
        regional_manager_id: data.user_regional_id,
        corban_id: `${data.corban_id} - ${data.corban_name}`,
        corban_name: data.corban_name,
        subestablishment_id: data.sub_id
          ? `${data.sub_id} - ${data.sub_name}`
          : '',
        sub_id: data.sub_id ? `${data.sub_id} - ${data.sub_name}` : '',
        complement: data.address.complement,
        bank_name: `${data.bank_account.number_bank} - ${data.bank_account.name_bank}`,
        agency_account: data.bank_account.agency_account,
        number_account: data.bank_account.number_account,
        agency_digit: data.bank_account.agency_digit,
        account_digit: data.bank_account.account_digit,
        account_kind: data.bank_account.kind,
        kind_pix: data.bank_account.kind_pix,
        pix: data.bank_account.pix,
        auto: data.auto,
        internal_code: data.internal_code,
        has_commission: data.entity.has_commission ? 'yes' : 'no',
        commission_type: data.entity.has_commission
          ? data.commission[0].commission_type == 'percentage'
            ? '0'
            : '1'
          : '',
        value: data.entity.has_commission
          ? data.commission[0].commission_type == 'percentage'
            ? `${data.commission[0].value}%`
            : `R$ ${data.commission[0].value}`
          : '',
        active_base_trigger: data.commission[0]
          ? data.commission[0].trigger != null &&
            data.commission[0].trigger != 0
            ? data.entity.has_commission
              ? 'yes'
              : ''
            : 'no'
          : '',
        trigger:
          data.entity.has_commission &&
          data.commission[0].trigger != null &&
          data.commission[0].trigger != 0
            ? `${data.commission[0].trigger}%`
            : '',
        entity_id: data.entity.id,
        address_id: data.address.id,
        bank_id: data.bank_account.id,
        commission_id: data.entity.has_commission ? data.commission[0].id : '',
        user_id: data.legal_representate ? data.legal_representate.id : '',
        user_cpf: data.legal_representate ? data.legal_representate.cpf : '',
        user_name: data.legal_representate ? data.legal_representate.name : '',
        user_email: data.legal_representate
          ? data.legal_representate.email
          : '',
        user_mobile: data.legal_representate
          ? data.legal_representate.mobile
          : '',
        support_whats: data.support_whats ? data.support_whats : false,
      });
      hideLoader();
    } catch (error) {
      hideLoader();
    }
  }

  useEffect(() => {
    getFgtsBasicInterest();
    async function getBanks() {
      showLoader();

      try {
        const response = await api.get(`banks?q[active_eq]=true`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });

        const values = response.data.map((value, index) => {
          return {
            value: `${value.code} - ${value.name}`,
            label: `${value.code} - ${value.name}`,
          };
        });

        console.log(values);
        setBanks(values);

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os bancos.');
      }
    }

    getBanks();
  }, []);

  useEffect(() => {
    if (
      user &&
      user.roles[0] &&
      (user.roles[0].name == 'master' ||
        user.roles[0].name == 'operational' ||
        user.roles[0].name == 'master_corban')
    ) {
      getManagers();
    }
  }, [user]);

  async function onSubmit(data) {
    showLoader();
    let corbanId = null;
    let subId = null;

    if (!store.kind_pix) {
      toastError('Selecione o tipo de chave pix');
      hideLoader();
      return;
    }

    if (store.kind_pix != 'random' && !store.pix) {
      toastError('Preencher campo pix');
      hideLoader();
      return;
    }

    if (store.sub_id && store.sub_id.split('-')[0] != 0) {
      subId =
        store.sub_id.split('-')[0] == 0 ? null : store.sub_id.split('-')[0];
    }

    if (store.corban_id) {
      corbanId =
        store.corban_id.split('-')[0] == 0
          ? null
          : store.corban_id.split('-')[0];
    }

    data = {
      store: {
        subestablishment_id: subId,
        user_regional_id: store.regional_manager_id,
        auto: store.auto,
        corban_id: corbanId,
        kind_table: store.kind_table,
        code_qista: store.code_qista,
        attendance_method: store.attendance_method,
        kind: 'pj',
        // support_whats: store.support_whats === 'true' ? true : false,
        entity_attributes: {
          cpf_cnpj: store.cpf_cnpj,
          name: store.name,
          commission_fgts: store.commission_fgts,
          social_reason: store.social_reason,
          id: store.entity_id,
          has_commission: store.has_commission == 'yes' ? true : false,
          address_attributes: {
            id: store.address_id,
            zip_code: store.zip_code,
            street: store.street,
            number: store.number,
            district: store.district,
            city: store.city,
            state: store.state,
            complement: store.complement,
          },
          bank_account_attributes: {
            id: store.bank_id,
            number_bank: store.bank_name.split('-')[0],
            name_bank: store.bank_name.split('-')[1],
            agency_account: store.agency_account,
            agency_digit: store.agency_digit,
            number_account: store.number_account,
            account_digit: store.account_digit,
            kind: store.account_kind,
            kind_pix: store.kind_pix,
            pix: store.pix,
          },
          contacts_attributes: [...contactFields],
          commission_bases_attributes: [
            {
              id: store.commission_id,
              commission_type: parseInt(store.commission_type, 10),
              value: store.value
                ? store.value.toString().replace('R$', '').replace(',', '.')
                : store.value,
              trigger: store.trigger,
            },
          ],
        },
      },
    };

    if (!corbanId) {
      console.log('ENTRANDO AQUI');
      data['store']['entity_attributes']['fgts_commissions_attributes'] = [
        ...fgtsCommissions.map((item) => {
          return {
            ...item,
            id: item.removeId ? null : item.id,
            commission_type: 0,
            value:
              item.commission_type == '0'
                ? item.value.toString().replace(',', '.')
                : item.value,
          };
        }),
      ];
    }

    if (store.password) {
      data = {
        ...data,
        store: {
          ...data.store,
          users_attributes: [
            {
              id: store.user_id,
              password: store.password,
            },
          ],
        },
      };
    }

    console.log(data);
    hideLoader();

    try {
      await api.put(`stores/${id}`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Estabelecimento atualizado com sucesso');

      props.history.push('/admin/establishments');
    } catch (error) {
      hideLoader();

      let message =
        'Ops, tivemos um erro na atualização do loja, por favor tente novamente!';

      console.log('TIVEMOS', error.response.data.error);

      if (error.response) {
        if (error.response.data.error) {
          const responseErrors = error.response.data.error;
          responseErrors.forEach((e) => {
            if (e.key == 'Documento' && e.model == 'users') {
              setError('user_cpf', 'inválido');
            } else if (e.key == 'Documento' && e.model == 'entity') {
              setError('cpf_cnpj', 'inválido');
            } else if (e.key == 'email' && e.model == 'users') {
              setError('user_email', 'inválido');
            } else if (e.key == 'agency_account' && e.model == 'entity') {
              toastError(`Agencia: ${e.value}`);
            } else if (e.key == 'trigger' && e.model == 'entity') {
              setError('trigger', 'inválido');
            }
          });
        }
      }
      window.scrollTo(0, 0);
      toastError('Há informações incorretas, por favor revise-as.');
    }
  }

  function parseCurrency(value) {
    if (typeof value === 'string' || value instanceof String) {
      return Number(value.replace('.', '').replace(',', '.').replace('R$', ''));
    } else {
      return value;
    }
  }

  function parsePercentage(value) {
    if (typeof value === 'string' || value instanceof String) {
      return Number(value.replace('%', '').replace(',', ''));
    } else {
      return value;
    }
  }

  function parseDigit(value) {
    if (typeof value === 'string' || value instanceof String) {
      return value.replace(/[^\d]+/g, 'x');
    } else {
      return value;
    }
  }

  function handleOnChange(e) {
    const values = { ...store };

    if (e.target.name == 'value_percentage') {
      setStore({ ...store, ['value']: e.target.value });
    } else if (e.target.name == 'trigger') {
      setStore({ ...store, ['trigger']: parsePercentage(e.target.value) });
    } else if (e.target.name == 'commission_type' && values['value'] != '') {
      setStore({ ...store, [e.target.name]: e.target.value, value: '' });
    } else if (e.target.name == 'agency_digit') {
      setStore({ ...store, ['agency_digit']: parseDigit(e.target.value) });
    } else if (e.target.name == 'account_digit') {
      setStore({ ...store, ['account_digit']: parseDigit(e.target.value) });
    } else if (e.target.name == 'active_base_trigger') {
      setStore({ ...store, [e.target.name]: e.target.value, trigger: 0 });
    } else if (e.target.name == 'cpf_cnpj') {
      if (e.target.value.length < 19) {
        let cpf_cnpj = e.target.value;

        cpf_cnpj = cpf_cnpj.replace(/^(\d{2})(\d)/, '$1.$2');

        cpf_cnpj = cpf_cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

        cpf_cnpj = cpf_cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');

        cpf_cnpj = cpf_cnpj.replace(/(\d{4})(\d)/, '$1-$2');
        setStore({ ...store, [e.target.name]: cpf_cnpj });
      }
    } else if (e.target.name == 'user_cpf') {
      if (e.target.value.length < 15) {
        let user_cpf = e.target.value;
        if (user_cpf.length >= 12) {
          user_cpf = user_cpf
            .replace(/[^0-9]/g, '')
            .replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
        } else {
          user_cpf = user_cpf.replace(/(\d{3})(\d)/, '$1.$2');
        }
        setStore({ ...store, [e.target.name]: user_cpf });
      }
    } else if (e.target.name == 'user_mobile') {
      if (e.target.value.length < 16) {
        let user_mobile = e.target.value;

        if (user_mobile.length > 10) {
          user_mobile = user_mobile
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{2})(\d{5})(\d)/, '($1) $2-$3');
        } else {
          user_mobile = user_mobile.replace(/^(\d{2})(\d)/, '($1) $2');
        }
        setStore({ ...store, [e.target.name]: user_mobile });
      }
    } else {
      setStore({ ...store, [e.target.name]: e.target.value });
    }
  }

  function handleOnChangeValueFix(e) {
    setStore({
      ...store,
      value: parseCurrency(e),
    });
  }

  function handleOnAgencyDigits(e) {
    setStore({
      ...store,
      agency_digit: parseDigit(e.target.agency_digit),
    });
  }

  function handleOnAccountDigits(e) {
    setStore({
      ...store,
      account_digit: parseDigit(e.target.account_digit),
    });
  }

  function dataAddress({ street, district, city, state }) {
    setStore({
      ...store,
      street: street,
      district: district,
      city: city,
      state: state,
    });
  }

  function handleOnChangeContact(index, event) {
    const values = [...contactFields];
    if (event.target.name == 'email_contact') {
      values[index]['email'] = event.target.value;
    } else if (event.target.name == 'mobile_contact') {
      values[index]['mobile'] = event.target.value;
    }

    setContactFields(values);
  }

  function handleClickContact() {
    setContactFields([
      ...contactFields,
      { mobile: '', email: '', type_contact: '' },
    ]);
  }

  function handleClickContactRemove(index) {
    const values = [...contactFields];
    values.splice(index, 1);
    setContactFields(values);
  }

  function handleOnChangeTypeContact(event, index) {
    const values = [...contactFields];
    console.log(index);
    console.log(values[index]);
    if (event.target.value == 'email') {
      values[index].type_contact = event.target.value;
    } else if (event.target.value == 'mobile') {
      values[index].type_contact = event.target.value;
    }

    setContactFields(values);
  }

  function handleOnChangeBank(e) {
    setStore({ ...store, bank_name: e.value });
  }

  async function handleOnChangeCorban(e) {
    getSubs(extratNumber(e.target.value));
    console.log('ALTERANDON NOME', e.target.value);

    setCorbanName(e.target.value);
    if (
      e.target.value.length > 4 &&
      user.roles[0] &&
      (user.roles[0].name == 'master' || user.roles[0].name == 'operational')
    ) {
      const response = await api.get(
        `/corbans/searchs?q[entity_name_cont]=${e.target.value}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      const values = response.data.map((value, index) => {
        return {
          value: `${value.id} - ${value.name}`,
          label: `${value.id} - ${value.name}`,
        };
      });
      setCorbans(values);
    } else if (e.target.value === '') {
      console.log('SALVANDO SEM NOME');
      setStore({ ...store, corban_id: null, corban_name: '' });
      setCorbans([]);
    }
  }

  function extratNumber(str) {
    const match = str.match(/^\d+/); // Encontra a sequência de números no início da string
    return match ? match[0] : ''; // Retorna a sequência encontrada, ou uma string vazia se nada for encontrado
  }

  function handleClickItemListFilter(value) {
    setCorbanName(value);
    setCorbans([]);
    swal({
      text: 'Deseja copiar os dados do CORBAN?',
      icon: 'success',
      buttons: ['Não', 'Sim'],
      dangerMode: true,
    }).then(async (next) => {
      if (next) {
        const response = await api.get(`/get_corban/${extratNumber(value)}`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });
        setDisabledSubestablishment(true);
        setStore({
          ...store,
          corban_id: value,
          cpf_cnpj: response.data.info_sub.cpf_cnpj,
          social_reason: response.data.info_sub.social_reason,
          name: response.data.info_sub.name,
          user_cpf: response.data.legal_representative.cpf,
          user_name: response.data.legal_representative.name,
          user_email: response.data.legal_representative.email,
          user_mobile: response.data.legal_representative.mobile,
          zip_code: response.data.address.zip_code,
          street: response.data.address.street,
          number: response.data.address.number,
          district: response.data.address.district,
          city: response.data.address.city,
          state: response.data.address.state,
          complement: response.data.address.complement,
          internal_code: response.data.internal_code,
        });
      } else {
        setDisabledSubestablishment(false);
        console.log('LOJA');
        setStore({ ...store, corban_id: value, corban_name: value });
      }
    });
  }

  function handleOnChangeSub(e) {
    setStore({ ...store, sub_id: e.value });
  }

  function insertPercentage(e) {
    if (e.target.name == 'value_percentage') {
      setStore({ ...store, ['value']: `${e.target.value}%` });
    } else {
      if (e.target.name == 'commission_fgts') {
        setStore({ ...store, ['commission_fgts']: `${e.target.value}%` });
      } else {
        setStore({ ...store, ['trigger']: `${e.target.value}%` });
      }
    }
  }

  function insertMoney(e) {
    if (e.target.value.charAt(0) != 'R') {
      setStore({ ...store, ['value']: `R$ ${e.target.value}` });
    }
  }

  function handleOnChangeSub(e) {
    if (e.value !== '' && !disabledSubestablishment) {
      swal({
        text: 'Deseja copiar os dados do Subestabelecimento?',
        icon: 'success',
        buttons: ['Não', 'Sim'],
        dangerMode: true,
      }).then(async (next) => {
        if (next) {
          const response = await api.get(
            `/get_subestablishment/${extratNumber(e.value)}`,
            {
              headers: {
                Authorization: `Bearer ${token()}`,
              },
            }
          );
          setStore({
            ...store,
            cpf_cnpj: response.data.info_sub.cpf_cnpj,
            social_reason: response.data.info_sub.social_reason,
            name: response.data.info_sub.name,
            user_cpf: response.data.legal_representative.cpf,
            user_name: response.data.legal_representative.name,
            user_email: response.data.legal_representative.email,
            user_mobile: response.data.legal_representative.mobile,
            zip_code: response.data.address.zip_code,
            street: response.data.address.street,
            number: response.data.address.number,
            district: response.data.address.district,
            city: response.data.address.city,
            state: response.data.address.state,
            complement: response.data.address.complement,
            internal_code: response.data.internal_code,
            sub_id: e.value,
          });
        } else {
          setStore({ ...store, sub_id: e.value });
        }
      });
    }
    setStore({ ...store, sub_id: e.value });
  }

  async function getFgtsBasicInterest() {
    if (!fgtsCommissions || (fgtsCommissions && fgtsCommissions.length <= 0)) {
      showLoader();

      try {
        let arrayCommissions = [
          {
            id: 1,
            tac: 12,
            name: 'Tabela Light CDC',
            commission_type: 0,
            value: 6,
            rate: 1.55,
            value_greater_thousand: 6,
            enabled: true,
            removeId: true,
            kind: 'grade',
          },
          {
            id: 2,
            tac: 18,
            name: 'Tabela Gold CDC',
            commission_type: 0,
            value: 12,
            rate: 1.79,
            value_greater_thousand: 12,
            enabled: true,
            removeId: true,
            kind: 'grade',
          },
          {
            id: 3,
            tac: 30,
            name: 'Tabela Gold CDC',
            commission_type: 0,
            value: 12,
            rate: 1.79,
            value_greater_thousand: 12,
            enabled: true,
            removeId: true,
            kind: 'grade',
          },
          {
            id: 4,
            tac: 30,
            name: 'Tabela Light CDC',
            commission_type: 0,
            value: 6,
            rate: 1.55,
            value_greater_thousand: 6,
            enabled: true,
            removeId: true,
            kind: 'grade',
          },
        ];

        setFgtsCommissions(arrayCommissions);
        console.log('SETANDO BASICA FGTS');
        console.log(arrayCommissions);

        getStore(arrayCommissions);

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os Juros Básicos.');
      }
    }
  }

  function handleOnChangeCommissionFgts(event, id, index) {
    const values = [...fgtsCommissions];
    index = values.indexOf(values.filter((item) => item.id === id)[0]);

    if (
      event.target.name === 'value_percentage' ||
      event.target.name == 'trigger' ||
      event.target.name == 'value_greater_thousand'
    ) {
      if (event.target.name === 'value_percentage') {
        values[index]['value'] = event.target.value;
      } else if (event.target.name === 'value_greater_thousand') {
        values[index]['value_greater_thousand'] = event.target.value;
      } else {
        values[index]['trigger'] = event.target.value;
      }
    } else if (
      event.target.name == 'commission_type' &&
      values[index]['value'] != ''
    ) {
      values[index]['value'] = '';
    }
    values[index][event.target.name] = event.target.value;
    console.log(values);
    setFgtsCommissions(values);
  }
  return (
    <>
      <CustomerHeader />
      <StoreForm
        store={store}
        managers={managers}
        contactFields={contactFields}
        banks={banks}
        isQista
        fgtsCommissions={fgtsCommissions}
        handleOnChangeCommissionFgts={handleOnChangeCommissionFgts}
        corbans={corbans}
        handleClickItemListFilter={handleClickItemListFilter}
        corbanName={corbanName}
        setCorbanName={setCorbanName}
        subs={subs}
        title="Atualizar Estabelecimento"
        titleButton="Atualizar Estabelecimento"
        onSubmit={onSubmit}
        handleOnChange={handleOnChange}
        handleOnAgencyDigits={handleOnAgencyDigits}
        handleOnAccountDigits={handleOnAccountDigits}
        handleOnChangeValueFix={handleOnChangeValueFix}
        handleOnChangeContact={handleOnChangeContact}
        handleClickContact={handleClickContact}
        handleClickContactRemove={handleClickContactRemove}
        handleOnChangeTypeContact={handleOnChangeTypeContact}
        handleOnChangeBank={handleOnChangeBank}
        handleOnChangeCorban={handleOnChangeCorban}
        handleOnChangeSub={handleOnChangeSub}
        dataAddress={dataAddress}
        controller={'edit'}
        insertPercentage={insertPercentage}
        insertMoney={insertMoney}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    </>
  );
};

export default UpdateEstablishment;
